<template>
	<dialog id="cine-dialog" v-draggable :open="isOpen">
		<form id="cine-dialog-form" class="no-select">
			<div class="cine-navigation">
				<div class="btn-group">
					<button
						class="btn"
						title="Previous display set"
						@click.prevent="$emit('goto-previous-series')"
					>
						<svg-icon icon="caret-square-o-up" />
					</button>
					<button
						class="btn"
						title="Next display set"
						@click.prevent="$emit('goto-next-series', 'first')"
					>
						<svg-icon icon="caret-square-o-down" />
					</button>
				</div>
			</div>

			<div class="cine-controls">
				<div class="btn-group">
					<button
						class="btn"
						title="Skip to first image"
						@click.prevent="$emit('goto-image', 'first')"
					>
						<svg-icon icon="fast-backward" />
					</button>
					<button
						class="btn"
						title="Previous image"
						@click.prevent="$emit('goto-image', 'previous')"
					>
						<svg-icon icon="step-backward" />
					</button>

					<!-- Play/pause -->
					<button
						v-show="!isPlaying"
						class="btn"
						title="Play / Stop"
						@click.prevent="$emit('play-clip')"
					>
						<svg-icon icon="play" />
					</button>
					<button
						v-show="isPlaying"
						class="btn"
						title="Play / Stop"
						@click.prevent="$emit('pause-clip')"
					>
						<svg-icon icon="pause" />
					</button>

					<button class="btn" title="Next image" @click.prevent="$emit('goto-image', 'next')">
						<svg-icon icon="step-forward" />
					</button>
					<button
						class="btn"
						title="Skip to last image"
						@click.prevent="$emit('goto-image', 'last')"
					>
						<svg-icon icon="fast-forward" />
					</button>
				</div>
			</div>

			<div class="cine-options">
				<div class="fps-section">
					<div class="wrapperLabel form-group" data-key="framesPerSecond">
						<span class="wrapperText"></span>
						<input
							type="range"
							class="form-control allow-keybinds"
							min="1"
							max="90"
							maxlength="90"
							:value="frameRate"
							@change="$emit('framerate-change', $event)"
							@input="$emit('framerate-change', $event)"
							@mousedown.stop
							@touchstart.stop
						/>
					</div>
				</div>

				<span id="fps">{{ frameRate }} fps</span>
			</div>
		</form>
	</dialog>
</template>

<script>
export default {
	name: 'CineDialog',
	props: {
		isOpen: {
			type: Boolean,
			required: true,
		},
		isPlaying: {
			type: Boolean,
			required: true,
		},
		frameRate: {
			type: Number,
			default: 30,
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

#cine-dialog {
	position: absolute;
	top: 100px;
	left: 5px;
	margin: 0;
	z-index: map-get($zindex, viewerDialog);
	width: 300px;
	padding: 0;
	overflow: hidden;
	cursor: move;
	zoom: 1;
	background: var(--secondary-bg);
	border: 1px solid var(--secondary-border);
	border-radius: 8px;
	box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

#cine-dialog-form {
	top: 2%;
	left: 35%;
	box-sizing: border-box;
	width: 300px;
	height: 80px;
	padding: 10px;
}

.cine-navigation {
	position: absolute;
	top: 4px;
	right: 4px;
	cursor: default;
}

.cine-controls {
	left: 0;
	cursor: default;
	margin-bottom: 5px;
}

.cine-options {
	padding: 0 0;
	cursor: default;

	.fps-section {
		float: left;
		width: 175px;
	}

	input {
		max-width: 100%; // IE
	}

	#fps {
		color: var(--primary-label);
		float: right;
		padding: 5px 10px 0 0;
	}
}

.btn {
	color: var(--primary-label);
	background: transparent;
	padding: 4px 6px;
	margin-bottom: 0;
	font-size: 14px;
	font-weight: normal;
	line-height: 1.42857143;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-image: none;
	border: 1px solid transparent;
	border-radius: 4px;
	&:hover {
		background: var(--button-default-bg-hover);
	}
	& .icon {
		opacity: 1;
		margin: 0;
	}
}

.btn-group {
	position: relative;
	display: inline-block;
	vertical-align: middle;

	& > .btn {
		position: relative;
		float: left;
	}

	& > .btn:first-child {
		margin-left: 0;
	}
}

.no-select {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
</style>
